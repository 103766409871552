// noinspection DuplicatedCode
import { NotFoundError } from "../../utils/errors/NotFoundError";
import { RightsError } from "../../utils/errors/RightsError";
import { AuthStore } from "../auth/auth.store";
import { FormType } from "./models/common.models";
import { EventEntity } from "./models/events/events.models";
import { memberActions } from "./models/member/member.actions";
import { ActivityMemberEntity, BiographyEntity, MemberConsent } from "./models/member/member.models";
import { SrmApiService } from "./srm.api.service";
import SrmStore from "./srm.store";

class SrmService {
    private readonly srmApiService: SrmApiService;

    constructor(private srmStore: SrmStore, private authStore: AuthStore) {
        this.srmApiService = new SrmApiService(false, authStore);
    }

    getEvents(): Promise<EventEntity[]> {
        return this.srmApiService.getEvents();
    }

    // load all proposals and return stage
    loadProposals = async () => {
        if (!this.authStore.isSrmUser && this.srmStore.proposalsNotLoaded) {
            this.srmStore.setProposals([]);
            return Promise.resolve();
        }

        // TODO set filters and paging
        try {
            const proposals = await this.srmApiService.getProposals(100);
            this.srmStore.setProposals(proposals);
        } catch (error) {
            this.srmStore.setProposals([]);
            throw error;
        }
    };

    // load one proposal
    loadProposal = (id: number): Promise<void> =>
        this.srmApiService
            .getProposal(id)
            .then((proposal) => {
                return this.srmStore.setProposal(proposal);
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    this.srmStore.setProposal(null);
                    return null;
                }

                if (error instanceof RightsError) {
                    this.srmStore.setProposal(null);
                }

                throw error;
            })
            // load members for activity
            .then((proposal) => {
                if (proposal?.activity?.id !== undefined && proposal.activity?.rights?.canListMembers) {
                    return this.loadActivityMembers(proposal.activity.id);
                }
            });

    // patch whole activity
    saveActivity = (activityId: number, data: object) =>
        this.srmApiService.patchActivity(activityId, data).then((activity) => {
            this.srmStore.setActivity(activity);
        });

    // load all members for activity
    private loadActivityMembers = (activityId: number) =>
        this.srmApiService.getActivityMembers(activityId).then((members) => {
            this.srmStore.setMembers(members);
        });

    // save members field
    saveMemberData = (memberId: number, data: ActivityMemberEntity) =>
        this.srmApiService
            .patchActivityMember(memberId, memberActions.editAction.removeMemberNotEditableFields(data))
            .then((member) => {
                this.srmStore.updateMembers(member);
            });

    // post new photo
    saveMemberPhoto = (memberId: number, form: FormData) =>
        this.srmApiService.postMemberPhoto(memberId, form).then((response) => {
            this.srmStore.updateMembers(response?.data);
        });

    // post new photo
    public getMemberBiographies = (memberId: number) =>
        this.srmApiService
            .getActivityMemberBiographies(memberId)
            .then((response) => {
                return response.filter((b) => b.objectStatus === "archived");
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    return Promise.resolve([] as BiographyEntity[]);
                }

                throw error;
            });

    public getExtendedParticipantArchivedBiographies = (bios: BiographyEntity[]) =>
        this.getEvents().then((events) => {
            return memberActions.mergeParticipantBiographiesWithEvents(bios, events);
        });

    saveActivityPresentation = (activityId: number, form: FormData) =>
        this.srmApiService.postActivityPresentation(activityId, form).then((response) => {
            this.srmStore.setActivity(response);
        });

    saveMemberConsent = async (member: ActivityMemberEntity, accepted: boolean, type: FormType) => {
        const consent: MemberConsent = {
            ...(member?.eventProfile?.consent ?? {}),
            [type]: {
                accepted,
            },
        };

        const updatedMember = await this.srmApiService.patchActivityMember(member.id, {
            eventProfile: {
                consent,
            },
        });
        return this.srmStore.updateMembers(updatedMember);
    };

    getParticipant = (participantId: number) => this.srmApiService.getParticipant(participantId);

    addToCommunicationChannel = (eventId: number, proposalId: number) =>
        this.srmApiService.putCommunicationChannel(eventId, proposalId);
}

export default SrmService;
