import { ServerAction } from "../srm/models/common.models";
import { Entity } from "./common.models";
import { ActionModel, ActionResource, ActionType } from "./rights.models";

const hasActions = (o?: object): ServerAction[] => {
    if (!o) {
        return [];
    }
    if ("actions" in o) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return o.actions as ServerAction[];
    }
    return [];
};

export const extractActionsFromAPI = (o?: any): ActionModel[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return hasActions(o).map((a) => {
        const parsed = a.name.split(":");

        if (!parsed[0] || !parsed[1]) {
            return null;
        }

        const resources: string[] = parsed[0]?.split("/");
        const resource: string | undefined = resources[0];
        const nestedResource: string | undefined = resources[1];

        if (nestedResource) {
            return {
                nestedResource,
                resource,
                action: parsed[1],
            };
        }

        return {
            resource,
            action: parsed[1],
        };
    });
};

const can = (o: Entity<any> | undefined, action: ActionType) => {
    if (!o) {
        return false;
    }
    return extractActionsFromAPI(o).find((a: ActionModel) => a.action === action && !a.nestedResource) !== undefined;
};

const canNestedResource = (o: Entity<any> | undefined, nestedResource: ActionResource, action: ActionType) => {
    if (!o) {
        return false;
    }
    return (
        extractActionsFromAPI(o).find(
            (a: ActionModel) => a.action === action && a.nestedResource === nestedResource,
        ) !== undefined
    );
};

export const rightsActions = {
    can,
    canRead: (o?: Entity<any>) => can(o, "read"),
    canPatch: (o?: Entity<any>) => can(o, "patch"),
    canDelete: (o?: Entity<any>) => can(o, "delete"),
    canNestedResource,
    canListNestedResource: (o: Entity<any> | undefined, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "list"),
    canCreateNestedResource: (o: Entity<any> | undefined, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "create"),
    canReadNestedResource: (o: Entity<any> | undefined, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "read"),
    canPatchNestedResource: (o: Entity<any> | undefined, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "patch"),
    canDeleteNestedResource: (o: Entity<any> | undefined, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "delete"),
};
