export const eventsApiPath = "/events";

export const committeesApiPath = "/committee";
export const committeeEventsApiPath = `${committeesApiPath}/events`;
export const committeeEventApiPath = (eventId: number) => `${committeeEventsApiPath}/${eventId}`;
export const committeeEventArchiveApiPath = (eventId: number) => `${committeeEventApiPath(eventId)}/archive`;

export const proposalsApiPath = "/speaker-area/proposals";
export const proposalsWithPagesApiPath = (pageSize: number) => `${proposalsApiPath}?page_size=${pageSize}`;
export const proposalApiPath = (proposalId: number) => `${proposalsApiPath}/${proposalId}`;
export const proposalSpeakerRoomApiPath = (proposalId: number) => `${proposalApiPath(proposalId)}/speaker-room`;

export const participantsApiPath = "/participants";

export const notificationsApiPath = "/notifications";

const activitiesApiPath = "/activities";
export const activitiesByIdsApiPath = (ids: number[]) => `${activitiesApiPath}?ids=${ids.join(",")}`;

export const activityApiPath = (activityId: number) => `${activitiesApiPath}/${activityId}`;
export const activityPresentationApiPath = (activityId: number) =>
    `${activityApiPath(activityId)}/:upload-presentation`;

const committeeProposalsOrActivitiesByEventApiPath = (type: "activities" | "proposals", eventId: number) =>
    `${committeeEventApiPath(eventId)}/${type}`;

const committeeProposalByEventApiPath = (eventId: number, proposalId: number) =>
    `${committeeProposalsOrActivitiesByEventApiPath("proposals", eventId)}/${proposalId}`;

export const committeeProposalCommunicationChannelJoinApiPath = (eventId: number, proposalId: number) =>
    `${committeeProposalByEventApiPath(eventId, proposalId)}/communication-channel/join`;

export const membersByActivityApiPath = (activityId: number) => `${activityApiPath(activityId)}/members`;
const membersApiPath = `${activitiesApiPath}/members`;
export const memberApiPath = (memberId: number) => `${membersApiPath}/${memberId}`;

export const memberBiographiesApiPath = (memberId: number) => `${memberApiPath(memberId)}/biographies`;

export const memberPhotoApiPath = (memberId: number) => `${memberApiPath(memberId)}/:upload-photo`;

export const participantApiPath = (participantId: number) => `${participantsApiPath}/${participantId}`;
