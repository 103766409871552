// noinspection DuplicatedCode
import { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from "axios";
import { reaction, when } from "mobx";

import { getBaseUrl } from "../../../environment";
import { initAxios } from "../../utils/api-helpers";
import { AuthStore } from "../auth/auth.store";
import { ActivityApiResponse, ActivityEntity } from "./models/activity/activity.models";
import { EventEntity, EventsEntityApiResponse } from "./models/events/events.models";
import {
    ActivityMemberEntity,
    BiographiesApiResponse,
    BiographyEntity,
    ContributorEntity,
    MemberApiResponse,
    MembersApiResponse,
    ParticipantApiResponse,
} from "./models/member/member.models";
import { ProposalApiResponse, ProposalEntity, ProposalsApiResponse } from "./models/proposal/proposal.models";
import {
    activityApiPath,
    activityPresentationApiPath,
    committeeProposalCommunicationChannelJoinApiPath,
    eventsApiPath,
    memberApiPath,
    memberBiographiesApiPath,
    memberPhotoApiPath,
    membersByActivityApiPath,
    participantApiPath,
    proposalApiPath,
    proposalsApiPath,
    proposalsWithPagesApiPath,
} from "./srm.api.routes";

export class SrmApiService {
    private api: AxiosInstance;

    constructor(serverSide: boolean = false, authStore?: AuthStore, token?: string) {
        const baseURL = getBaseUrl(serverSide);
        this.api = initAxios(baseURL, authStore, token);

        if (authStore) {
            reaction(
                () => authStore?.accessToken,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => !authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );
        }
    }

    // GET /api/v1/events
    getEvents = (): Promise<EventEntity[]> =>
        this.api
            .get(eventsApiPath)
            .then((result: AxiosResponse<EventsEntityApiResponse>) => result.data?.data ?? ([] as EventEntity[]));

    // GET /api/v1/proposals
    getProposals = (pageSize?: number) =>
        this.api
            .get(pageSize ? proposalsWithPagesApiPath(pageSize) : proposalsApiPath)
            .then((result: AxiosResponse<ProposalsApiResponse>) => result.data?.data ?? ([] as ProposalEntity[]));

    // GET /api/v1/proposals/{id}
    getProposal = (id: number) =>
        this.api
            .get(proposalApiPath(id))
            .then((result: AxiosResponse<ProposalApiResponse>) => result.data?.data as ProposalEntity);

    // PATCH /api/v1/proposals/{id}
    patchProposal = (data: ProposalEntity) =>
        this.api
            .patch(proposalApiPath(data.id), data)
            .then((result: AxiosResponse<ProposalApiResponse>) => result.data?.data as ProposalEntity);

    // PATCH /api/v1/activities/{id}
    patchActivity = (id: number, data: ActivityEntity) =>
        this.api
            .patch(activityApiPath(id), data)
            .then((result: AxiosResponse<ActivityApiResponse>) => result.data?.data as ActivityEntity);

    // GET /api/v1/participants
    // getParticipants() {
    //     return this.api
    //         .get(participantsApiPath)
    //         .then((result: AxiosResponse<ParticipantsApiResponse>) => result.data?.data as ParticipantEntity[]);
    // }

    // GET /api/v1/participants/{id}
    getParticipant = (participantId: number) =>
        this.api
            .get(participantApiPath(participantId))
            .then((result: AxiosResponse<ParticipantApiResponse>) => result.data?.data as ContributorEntity);

    // GET /api/v1/activities/{id}/members
    getActivityMembers = (activityId: number) =>
        this.api
            .get(membersByActivityApiPath(activityId))
            .then((result: AxiosResponse<MembersApiResponse>) => result.data?.data as ActivityMemberEntity[]);

    // PATCH /api/v1/activities/members/{memberId}
    patchActivityMember = (memberId: number, data: ActivityMemberEntity) =>
        this.api
            .patch(memberApiPath(memberId), data)
            .then((result: AxiosResponse<MemberApiResponse>) => result.data?.data as ActivityMemberEntity);

    // GET /api/v2/activities/members/{memberId}/biographies
    getActivityMemberBiographies = (memberId: number, headers?: AxiosRequestHeaders) =>
        this.api
            .get(memberBiographiesApiPath(memberId), {
                headers,
            })
            .then((result: AxiosResponse<BiographiesApiResponse>) => result.data?.data as BiographyEntity[]);

    // POST /api/v1/activities/members/{memberId}/:upload-photo
    postMemberPhoto = (memberId: number, data: FormData) =>
        this.api
            .post(memberPhotoApiPath(memberId), data)
            .then((result: AxiosResponse<MemberApiResponse>) => result.data?.data as MemberApiResponse);

    // POST /api/v1/activities/{activityId}/:upload-presentation
    postActivityPresentation = (activityId: number, form: FormData) =>
        this.api
            .post(activityPresentationApiPath(activityId), form)
            .then((result: AxiosResponse<ActivityApiResponse>) => result.data?.data as ActivityEntity);

    putCommunicationChannel(eventId: number, proposalId: number) {
        return this.api.put(committeeProposalCommunicationChannelJoinApiPath(eventId, proposalId));
    }
}
