import { removeUndefined } from "../../../utils/common";
import { Entity, EntityNumber, LocalizeString } from "../../common/common.models";
import { memberActions } from "./member/member.actions";
import { BiographyEntity } from "./member/member.models";
import { ContentStatus, ObjectStatus } from "./squidex.models";

export const commonActions = {
    localizeStringActions: {
        isSomeNull: (l?: LocalizeString) => (l !== undefined ? !l.en || !l.ru : false),
    },
    contentActions: {
        isPublished: (c?: ContentStatus) => (c !== undefined ? c === "published" : false),
        isEdited: (c?: ContentStatus) => (c !== undefined ? c === "edited" : false),
        allIsExactlyPublished: (cs: ContentStatus[]) => cs.every((c) => c === "published"),
        isDraft: (c?: ContentStatus) => (c !== undefined ? c === "draft" : false),
        oneIsDraft: (cs: ContentStatus[]) => cs.some((c) => c === "draft"),
        isArchive: (c?: ContentStatus) => (c !== undefined ? c === "archived" : false),
        oneIsArchive: (cs: ContentStatus[]) => cs.some((c) => c === "archived"),
    },
    entityActions: {
        isActive: (c?: ObjectStatus) => (c !== undefined ? c === "active" : false),
        sortById: (a: EntityNumber, b: EntityNumber) => a.id - b.id,
        removeEntityNotEditableFields: (data: object): Entity<any> =>
            removeUndefined({
                ...data,
                actions: undefined,
                createdAt: undefined,
                createdBy: undefined,
                hint: undefined,
                id: undefined,
                objectStatus: undefined,
                updatedAt: undefined,
                updatedBy: undefined,
            } as object),
    },
    imageActions: {
        getScaledImageUrl: (image?: string, width?: number, height?: number, mode?: string, cache?: number) => {
            if (!image) {
                return undefined;
            }

            const url = new URL(image);
            if (width) {
                url.searchParams.append("width", width.toString());
            }
            if (height) {
                url.searchParams.append("height", height.toString());
            }
            if (mode) {
                url.searchParams.append("mode", mode);
            }
            url.searchParams.append("cache", cache?.toString() ?? "3600");

            return url.toString();
        },
    },
    twitterActions: {
        makeTwitterLink: (biography?: BiographyEntity) => {
            if (!biography) {
                return undefined;
            }
            const twitter = memberActions.extractTwitterData(biography);

            if (!twitter) {
                return undefined;
            }
            return `https://twitter.com/${twitter}`;
        },
        getFieldValueWithoutSymbols: (v: string, symbols: string | RegExp) => v?.replaceAll(symbols, ""),
    },
};
