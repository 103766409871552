import { TagSeverityType } from "primereact/tag";

import { LocaleMessagesType } from "../../../../hooks/useI18nLocaleFormat";
import { rightsActions } from "../../../common/rights.actions";
import { activityActions } from "../activity/activity.actions";
import {
    ProposalAdditionalActionsAvailableStages,
    ProposalAdditionalActionsNotAvailableStatuses,
    ProposalClosedStatuses,
    ProposalProductionStages,
} from "./proposal.consts";
import { ProposalEntity, ProposalFacet } from "./proposal.models";

export const proposalActions = {
    isClosedProposal: (proposal?: ProposalEntity) => ProposalClosedStatuses.includes(proposal?.workflow?.status),
    isNonClosedProposal: (proposal?: ProposalEntity) => !ProposalClosedStatuses.includes(proposal?.workflow?.status),
    isProposalProductionStage: (proposal?: ProposalEntity | ProposalFacet) =>
        // @ts-ignore
        ProposalProductionStages.includes(proposal?.workflow?.stage) ||
        // @ts-ignore
        ProposalProductionStages.includes(proposal?.stage),
    isAdditionalActionsAvailable: (proposal?: ProposalEntity) =>
        !ProposalAdditionalActionsNotAvailableStatuses.includes(proposal?.workflow?.status) &&
        ProposalAdditionalActionsAvailableStages.includes(proposal?.workflow?.stage),
    getDescriptionForStatus: (
        proposal: ProposalEntity | undefined,
        localeAs: (id: LocaleMessagesType) => string,
    ): { title: string; description: string; severity: TagSeverityType } => {
        const severity: TagSeverityType = proposalActions.isNonClosedProposal(proposal)
            ? "success"
            : proposalActions.isClosedProposal(proposal)
            ? "info"
            : "warning";

        if (proposal?.workflow?.status) {
            return {
                title: localeAs(`domain.proposal.workflow.status.${proposal?.workflow?.status}` as any),
                description: localeAs(
                    `domain.proposal.workflow.status.${proposal?.workflow?.status}.description` as any,
                ),
                severity,
            };
        }
        return { title: "", description: "", severity };
    },
    addRightsToProposal: (data?: ProposalEntity): ProposalEntity => {
        return {
            ...(data ?? {}),
            activity: activityActions.addRightsToActivity(data?.activity),
            rights: {
                canRead: true,
                canPatch: rightsActions.canPatch(data),
            },
        };
    },
};
