import { removeUndefined } from "../../../../utils/common";
import { EntityTypeValue } from "../../../common/common.models";
import { ActivityScope, ActivityTypeValue } from "./activity.models";

export const ActivityEntityTypeValuePrefix = "domain.proposal.activity.typeValue";
export const ActivityEntityScopeValuePrefix = "domain.proposal.activity.scope";

export const ActivityEntityLocalizedIds = {
    content: {
        status: "domain.proposal.activity.content.status",
        data: {
            shortDescription: {
                title: "domain.proposal.activity.content.data.shortDescription",
                note: "domain.proposal.activity.content.data.shortDescription.hint",
                editNote: "domain.proposal.activity.content.data.shortDescription.edit.hint",
            },
            fullDescription: {
                title: "domain.proposal.activity.content.data.fullDescription",
                note: "domain.proposal.activity.content.data.fullDescription.hint",
                editNote: "domain.proposal.activity.content.data.fullDescription.edit.hint",
            },
            presentation: "domain.proposal.activity.content.data.presentation",
            complexity: "domain.proposal.activity.content.data.complexity",
            language: "domain.proposal.activity.content.data.language",
            tags: "domain.proposal.activity.content.data.tags",
            technicalTags: "domain.proposal.activity.content.data.technicalTags",
            topics: "domain.proposal.activity.content.data.topics",
            platforms: "domain.proposal.activity.content.data.platforms",
            links: {
                discussion: "domain.proposal.activity.content.data.links.discussion",
            },
            internalSlots: {
                broadcast: "domain.proposal.activity.content.data.internalSlots.broadcast",
                discussion: "domain.proposal.activity.content.data.internalSlots.discussion",
                discussionExternalLink: "domain.proposal.activity.content.data.internalSlots.discussionExternalLink",
            },
        },
    },
    plan: {
        title: "domain.proposal.activity.plan",
        note: "domain.proposal.activity.plan.hint",
        editNote: "domain.proposal.activity.plan.hint",
    },
    title: "domain.proposal.activity.title",
    typeValue: {
        title: ActivityEntityTypeValuePrefix,
        note: "ActivityTypeContentEditBlock.infoMessage",
        editNote: "ActivityTypeContentEditBlock.infoMessage",
    },
    broadcastOptions: {
        type: "domain.proposal.activity.broadcastOptions.type",
        importance: "domain.proposal.activity.broadcastOptions.importance",
        notes: "domain.proposal.activity.broadcastOptions.notes",
    },
};

export const ActivityEntityBroadcastTypeValues: EntityTypeValue<string>[] = [
    {
        key: "live",
        displayId: "domain.proposal.activity.broadcastOptions.type.LIVE",
    },
    {
        key: "record",
        displayId: "domain.proposal.activity.broadcastOptions.type.RECORD",
    },
];

export const DisabledActivityEntityTypeValue: ActivityTypeValue[] = ["ROUND_TABLE"];

export const ActivityEntityTypeValueValuesSanitize = (includes: string[], scope: ActivityScope) =>
    ActivityEntityTypeValueValues(scope).filter(
        (v) => includes.includes(v.key) || !DisabledActivityEntityTypeValue.includes(v.key as ActivityTypeValue),
    );

export const ActivityEntityTypeValueValues = (scope?: ActivityScope): EntityTypeValue<string>[] => {
    return removeUndefined([
        {
            key: "TALK",
            displayId: `${ActivityEntityTypeValuePrefix}.TALK`,
        },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "ROUND_TABLE",
                  displayId: `${ActivityEntityTypeValuePrefix}.ROUND_TABLE`,
                  disabled: true,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "WORKSHOP",
                  displayId: `${ActivityEntityTypeValuePrefix}.WORKSHOP`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "BATTLE",
                  displayId: `${ActivityEntityTypeValuePrefix}.BATTLE`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "BOF_SESSION",
                  displayId: `${ActivityEntityTypeValuePrefix}.BOF_SESSION`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "MASTERCLASS",
                  displayId: `${ActivityEntityTypeValuePrefix}.MASTERCLASS`,
              },
        {
            key: "CONVERSATION",
            displayId: `${ActivityEntityTypeValuePrefix}.CONVERSATION`,
        },
        {
            key: "INTERVIEW",
            displayId: `${ActivityEntityTypeValuePrefix}.INTERVIEW`,
        },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "ANALYSIS",
                  displayId: `${ActivityEntityTypeValuePrefix}.ANALYSIS`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "GAME",
                  displayId: `${ActivityEntityTypeValuePrefix}.GAME`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "CV_INTERVIEW",
                  displayId: `${ActivityEntityTypeValuePrefix}.CV_INTERVIEW`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "UNDECIDED",
                  displayId: `${ActivityEntityTypeValuePrefix}.UNDECIDED`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "CUSTOM",
                  displayId: `${ActivityEntityTypeValuePrefix}.CUSTOM`,
              },
        {
            key: "MEETUP",
            displayId: `${ActivityEntityTypeValuePrefix}.MEETUP`,
        },
        {
            key: "ASK_EXPERT",
            displayId: `${ActivityEntityTypeValuePrefix}.ASK_EXPERT`,
        },
    ]);
};
