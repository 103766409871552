import { EventEntity } from "./events.models";

export const eventsAction = {
    comparatorEventByDate: (a?: EventEntity, b?: EventEntity) => {
        if (!a?.id && !b?.id) {
            return -1;
        }
        if (!a?.id) {
            return 1;
        }
        if (!b?.id) {
            return -1;
        }
        if (b?.period?.startsAt === a?.period?.startsAt) {
            return b.id - a.id;
        }
        if (b?.period?.startsAt > a?.period?.startsAt) {
            return 1;
        } else {
            return -1;
        }
    },
};
