import { makeAutoObservable } from "mobx";

import { AccessToken } from "./access-token";
import { COMPANIES_ADMIN_ROLE, GLOBAL_ADMIN_ROLE, SRM_USER_ROLE } from "./auth.models";

export class AuthStore {
    public accessToken?: AccessToken;
    public isLogged?: boolean = undefined;
    public userId?: number | undefined | null;
    public userEmail?: string;
    public displayName?: string;
    public picture?: string;

    private lkLang: string = "ru";
    private lkSessionId?: number;
    private sub?: string;
    private authorities?: string[];

    constructor() {
        makeAutoObservable(this);
    }

    private setDisplayName(displayName?: string) {
        this.displayName = displayName;
    }

    private setIsLogged(isLogged: boolean) {
        this.isLogged = isLogged;
    }

    private setUserId(userId?: number) {
        this.userId = userId;
    }

    private setUserEmail(userEmail?: string) {
        this.userEmail = userEmail;
    }

    private setLkLang(lang: string) {
        this.lkLang = lang.toLowerCase();
    }

    private setLkSessionId(lkSessionId?: number) {
        this.lkSessionId = lkSessionId;
    }

    private setSub(sub?: string) {
        this.sub = sub;
    }

    private setPicture(picture?: string) {
        this.picture = picture;
    }

    private setTokens(accessToken?: AccessToken) {
        this.accessToken = accessToken;
    }

    private setAuthorities(authorities?: string[]) {
        this.authorities = authorities;
    }

    public updateFromAccessToken(accessToken?: AccessToken): AuthStore {
        if (!accessToken || !accessToken.value) {
            this.setTokens(undefined);
            this.setIsLogged(false);
            this.setUserId(null);
            this.setUserEmail(undefined);
            this.setDisplayName(undefined);
            this.setLkLang("ru");
            this.setLkSessionId(undefined);
            this.setSub(undefined);
            this.setPicture(undefined);
            this.setAuthorities(undefined);
        } else {
            this.setTokens(accessToken);
            this.setIsLogged(true);
            this.setUserId(accessToken.value?.lk_user_id);
            this.setUserEmail(accessToken.value?.email);
            this.setDisplayName(accessToken.value?.displayName);
            this.setLkLang(accessToken.value?.lang);
            this.setLkSessionId(accessToken.value?.lk_session_id);
            this.setSub(accessToken.value?.sub);
            this.setPicture(accessToken.value?.picture);
            this.setAuthorities(accessToken.value?.authorities);
        }

        return this;
    }

    get accessTokenRaw(): string | undefined {
        return this.accessToken?.raw;
    }

    get isGlobalAdmin(): boolean {
        return this.authorities?.includes(GLOBAL_ADMIN_ROLE) ?? false;
    }

    get isCompaniesAdmin(): boolean {
        return this.authorities?.filter((a) => a.startsWith(COMPANIES_ADMIN_ROLE)).length > 0 ?? false;
    }

    get isSrmUser(): boolean {
        return this.authorities?.includes(SRM_USER_ROLE) ?? false;
    }
}
