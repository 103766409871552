import { removeUndefined } from "../../../../utils/common";
import { rightsActions } from "../../../common/rights.actions";
import { activityActions } from "../activity/activity.actions";
import { ActivityScope } from "../activity/activity.models";
import { commonActions } from "../common.actions";
import { Form } from "../common.models";
import { EquipmentEntity } from "../equipment.models";
import { eventsAction } from "../events/events.actions";
import { EventEntity } from "../events/events.models";
import { LocationModel } from "../location/location.model";
import { ProposalEntity } from "../proposal/proposal.models";
import { TravelModel } from "../travel/travel.model";
import { MemberEntityFormatValues } from "./member.consts";
import { ActivityMemberEntity, BiographyEntity, ExtendedBiographyEntity, MemberConsent } from "./member.models";

export const memberActions = {
    addRightsToMember: (data?: ActivityMemberEntity): ActivityMemberEntity => ({
        ...(data ?? {}),
        rights: {
            canPatch: rightsActions.canPatchNestedResource(data, "member"),
            canListBiography: rightsActions.canListNestedResource(data, "biography"),
            canDelete: rightsActions.canDeleteNestedResource(data, "member"),
            canUploadPhoto: rightsActions.canNestedResource(data, "member", "upload-photo"),
        },
    }),
    isMemberPublicationConsentAccepted: (member?: ActivityMemberEntity) => {
        if (!member) {
            return undefined;
        }

        return memberActions.isMemberConsentAccepted(member?.eventProfile?.consent);
    },
    isMemberConsentAccepted: (consent?: MemberConsent) => {
        if (!consent) {
            return false;
        }

        return consent.content_publishing.accepted;
    },
    getPublicationConsentForm: (consent?: MemberConsent): Form | undefined => {
        if (!consent) {
            return undefined;
        }

        return consent.content_publishing;
    },
    isSecondaryRole: (memberEntity?: ActivityMemberEntity): boolean => memberEntity?.role === "EXPERT",
    isTravelPossible: (memberEntity?: ActivityMemberEntity): boolean =>
        memberEntity?.participationFormat === undefined
            ? false
            : memberEntity?.participationFormat === "ONLINE_STUDIO" || memberEntity?.participationFormat === "OFFLINE",
    isLocationPossible: (memberEntity?: ActivityMemberEntity): boolean =>
        memberEntity?.participationFormat === undefined ? false : memberEntity?.participationFormat === "ONLINE_REMOTE",
    isGiftPossible: (memberEntity: ActivityMemberEntity): boolean =>
        memberEntity?.participationFormat === undefined ? false : true,
    // REMOVE by Oksana 20.04.2022
    // member?.format === "ONLINE_REMOTE" || member?.format === "ONLINE_STUDIO",
    getFormatLocalizeId: (memberEntity?: ActivityMemberEntity, scope?: ActivityScope): string | undefined =>
        memberEntity?.participationFormat === undefined
            ? undefined
            : MemberEntityFormatValues(scope).find((f) => f.key === memberEntity.participationFormat)?.displayId,
    getMe: (members?: ActivityMemberEntity[]) => members?.find((p) => p.isMe),
    getOtherMember: (members?: ActivityMemberEntity[]) => members?.filter((p) => !p.isMe),
    extractBiographyImages: (biography?: BiographyEntity) => {
        const photoIds = biography?.content?.data?.photo?.iv;

        if (!photoIds) {
            return undefined;
        }

        if (photoIds.length === 0) {
            return [];
        }

        const assets = biography?.content?.assets?.filter((a) => photoIds.includes(a.id));

        return assets?.map((a) => a.links?.content);
    },
    extractContentData: (biography?: BiographyEntity) => biography?.content?.data,
    extractTwitterData: (biography?: BiographyEntity) =>
        commonActions.twitterActions.getFieldValueWithoutSymbols(
            biography?.content?.data?.contacts?.iv?.filter((c) => c.type === "twitter")[0]?.value,
            "@",
        ),
    editAction: {
        removeMemberNotEditableFields: (data: object): ActivityMemberEntity =>
            removeUndefined(
                commonActions.entityActions.removeEntityNotEditableFields({
                    ...data,
                    activityId: undefined,
                    contributorId: undefined,
                    createdFrom: undefined,
                    isMe: undefined,
                    role: undefined,
                    scope: undefined,
                } as object),
            ),
        removeAdditionalNotEditableFields: (
            data: LocationModel | EquipmentEntity | TravelModel,
        ): LocationModel | EquipmentEntity | TravelModel =>
            removeUndefined({
                ...data,
                id: undefined,
                contributorId: undefined,
                actions: undefined,
            }),
        removeBiographyNotEditableFields: (data: BiographyEntity, withPhoto?: boolean): BiographyEntity =>
            removeUndefined(
                commonActions.entityActions.removeEntityNotEditableFields({
                    ...data,
                    content: {
                        ...data.content,
                        app: undefined,
                        assets: undefined,
                        createdAt: undefined,
                        data: {
                            ...data.content?.data,
                            id: undefined,
                            ownerId: undefined,
                            photo: withPhoto ? data.content?.data?.photo : undefined,
                        },
                        id: undefined,
                        schema: undefined,
                        status: undefined,
                        version: undefined,
                    },
                    contributorId: undefined,
                    workspaceId: undefined,
                }),
            ),
    },
    isEditingEnabled: (member?: ActivityMemberEntity, proposal?: ProposalEntity) => {
        if (!member || !proposal?.activity) {
            return false;
        }

        if (activityActions.isPartnerScope(proposal?.activity)) {
            return false;
        }

        return member.rights?.canPatch;
    },
    canEditSomeMember(currentMembers?: ActivityMemberEntity[]) {
        if (!currentMembers || currentMembers?.length === 0) {
            return false;
        }

        return currentMembers.some((member) => member.rights?.canPatch);
    },
    sortMembersByRole(a: ActivityMemberEntity, b: ActivityMemberEntity) {
        if (a.role === b.role) {
            return a.id - b.id;
        }

        if (a.role === "SPEAKER") {
            return -1;
        }

        if (a.role === "EXPERT" && b.role !== "SPEAKER") {
            return -1;
        }

        return 1;
    },
    sortBiographiesByEvent(a: ExtendedBiographyEntity, b: ExtendedBiographyEntity) {
        const aEvents = a.referencedBy
            ?.filter((r) => r.type === "event")
            .map((r) => r.entity as EventEntity)
            .sort(eventsAction.comparatorEventByDate);
        const bEvents = b.referencedBy
            ?.filter((r) => r.type === "event")
            .map((r) => r.entity as EventEntity)
            .sort(eventsAction.comparatorEventByDate);

        if (!aEvents || !bEvents) {
            return -1;
        }

        return eventsAction.comparatorEventByDate(aEvents[0], bEvents[0]);
    },
    mergeParticipantBiographiesWithEvents(bios: BiographyEntity[], events: EventEntity[]): ExtendedBiographyEntity[] {
        return bios
            .map((b) => {
                return {
                    ...b,
                    referencedBy: b.referencedBy?.map((r) => {
                        if (r?.type === "event") {
                            return {
                                ...r,
                                entity: events.find((a) => a.id === r.id),
                            };
                        }
                        return r;
                    }),
                } as ExtendedBiographyEntity;
            })
            .sort(memberActions.sortBiographiesByEvent);
    },
    getEventsFromBio(biography: ExtendedBiographyEntity) {
        return biography?.referencedBy
            ?.filter((r) => r.type === "event")
            .map((r) => r.entity as EventEntity)
            .sort(eventsAction.comparatorEventByDate);
    },
};
