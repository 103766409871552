import { makeAutoObservable } from "mobx";

import { activityActions } from "./models/activity/activity.actions";
import { ActivityEntity } from "./models/activity/activity.models";
import { memberActions } from "./models/member/member.actions";
import { ActivityMemberEntity } from "./models/member/member.models";
import { proposalActions } from "./models/proposal/proposal.actions";
import { ProposalEntity } from "./models/proposal/proposal.models";

class SrmStore {
    // undefined = is not loaded, null - is null
    proposals?: ProposalEntity[] | undefined | null = undefined;
    proposal?: ProposalEntity | undefined | null = undefined;
    members: ActivityMemberEntity[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get proposalsNotLoaded(): boolean {
        return this.proposals === undefined;
    }

    reset() {
        this.setProposals(undefined);
        this.setProposal(undefined);
        this.setMembers([]);
    }

    setProposals(proposals: ProposalEntity[]) {
        this.proposals = proposals.map(proposalActions.addRightsToProposal);
    }

    setProposal(proposal: ProposalEntity | null) {
        if (!proposal) {
            this.proposal = proposal;
            return proposal;
        }

        const data = proposalActions.addRightsToProposal(proposal);
        this.proposal = data;

        return data;
    }

    setActivity(activity: ActivityEntity) {
        if (this.proposal) {
            this.proposal.activity = activityActions.addRightsToActivity(activity);
        }
    }

    setMembers(members: ActivityMemberEntity[]) {
        this.members = members.map(memberActions.addRightsToMember);
    }

    updateMembers(member: ActivityMemberEntity) {
        const otherMembers = this.members.filter((p) => p.id !== member.id);
        this.members = [...otherMembers, memberActions.addRightsToMember(member)];
    }

    deleteMember(id: number) {
        this.members = this.members.filter((p) => p.id !== id);
    }

    get notClosedProposals(): ProposalEntity[] | undefined {
        return this.proposals?.filter(proposalActions.isNonClosedProposal);
    }

    get closedProposals(): ProposalEntity[] | undefined {
        return this.proposals?.filter(proposalActions.isClosedProposal);
    }
}

export default SrmStore;
