import React, { useRef } from "react";

import cn from "classnames";

import styles from "./TopBar.module.scss";

import { observer } from "mobx-react-lite";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

import { useDataContext } from "../../../../data/DataContext";
import { useI18nLocaleFormat } from "../../../../hooks/useI18nLocaleFormat";
import { useIsBrowser } from "../../../../hooks/useIsBrowser";
import APPLICATION_ROUTES from "../../../../utils/routes";

const Profile = () => {
    const { authStore } = useDataContext()?.stores;
    const { authService } = useDataContext()?.services;
    const { localeAs } = useI18nLocaleFormat();

    const { isLogged, picture, isGlobalAdmin } = authStore;

    const loggedMenu = useRef(null);

    const isBrowser = useIsBrowser();

    if (!isBrowser) {
        return null;
    }

    const loggedMenuItems: MenuItem[] = [
        { label: localeAs("auth.profile"), icon: "pi pi-user", url: APPLICATION_ROUTES.lkProfile.route },
        {
            label: localeAs("auth.logout"),
            icon: "pi pi-sign-out",
            command: () => {
                authService.logout().finally(() => window.location.reload());
            },
        },
    ];

    const getClassNameForAvatar = () =>
        cn("p-link", styles.topBar__button, styles.topBar__displayButton, {
            [styles.topBar__profile_logo_admin]: isGlobalAdmin,
        });

    const renderAvatar = () => {
        if (!isLogged) {
            return (
                <Avatar
                    className={cn("p-link", styles.topBar__button, styles.topBar__displayButton)}
                    icon="pi pi-user"
                    shape="circle"
                />
            );
        }
        if (isLogged && picture) {
            return (
                <Avatar
                    className={getClassNameForAvatar()}
                    image={authStore.picture}
                    shape="circle"
                    onClick={(event) => loggedMenu.current.toggle(event)}
                />
            );
        }
        if (isLogged && !picture) {
            return (
                <Avatar
                    className={getClassNameForAvatar()}
                    label={(authStore.displayName ?? "User")[0]}
                    shape="circle"
                    onClick={(event) => loggedMenu.current.toggle(event)}
                />
            );
        }
    };

    return (
        <>
            <li>{renderAvatar()}</li>
            <Menu model={authStore.isLogged ? loggedMenuItems : null} popup ref={loggedMenu} />
        </>
    );
};

export default observer(Profile);
