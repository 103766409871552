import { removeUndefined } from "../../../../utils/common";
import { LocalizeStringArrayDefault } from "../../../common/common.const";
import { LocalizeString, LocalizeStringArray } from "../../../common/common.models";
import { rightsActions } from "../../../common/rights.actions";
import SrmService from "../../srm.service";
import { AssetEntity } from "../squidex.models";
import { ActivityEntityTypeValueValues } from "./activity.consts";
import { ActivityContentData, ActivityEntity } from "./activity.models";

export const activityActions = {
    addRightsToActivity: (data?: ActivityEntity): ActivityEntity => ({
        ...(data ?? {}),
        rights: {
            canRead: rightsActions.canRead(data),
            canPatch: rightsActions.canPatch(data),
            canUploadPresentation: rightsActions.can(data, "upload-presentation"),
            canListMembers: rightsActions.canListNestedResource(data, "member"),
            canAddMember: rightsActions.canNestedResource(data, "member", "register"),
        },
    }),
    getTypeLocalizeId: (activity?: ActivityEntity): string | undefined =>
        activity?.typeValue === undefined
            ? undefined
            : ActivityEntityTypeValueValues().find((f) => f.key === activity.typeValue)?.displayId,
    extractOGImagesUrls: (activity?: ActivityEntity, deleteDefaultOg: boolean = false): LocalizeStringArray => {
        if (!activity) {
            return LocalizeStringArrayDefault;
        }

        const ogIds = activity?.content?.data?.OGImage;

        if (!ogIds) {
            return LocalizeStringArrayDefault;
        }

        const assets = activity?.content?.assets;

        if (deleteDefaultOg && ogIds.ru?.length > 0 && ogIds.en?.length > 0) {
            if (ogIds.ru[0] === ogIds.en[0]) {
                return {
                    ru: (assets?.filter((a) => ogIds.ru?.includes(a.id)) ?? []).map((a) => a.links?.content),
                    en: [],
                };
            }
        }

        return {
            ru: (assets?.filter((a) => ogIds.ru?.includes(a.id)) ?? []).map((a) => a.links?.content),
            en: (assets?.filter((a) => ogIds.en?.includes(a.id)) ?? []).map((a) => a.links?.content),
        };
    },
    extractPresentationFiles: (activity?: ActivityEntity): AssetEntity[] => {
        const ids = activity?.content?.data?.presentation?.iv?.files;

        if (!ids) {
            return undefined;
        }

        if (ids.length === 0) {
            return [];
        }

        return activity?.content?.assets
            ?.filter((a) => ids.includes(a.id))
            .sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1));
    },
    isPartnerScope: (activity?: ActivityEntity): boolean | undefined =>
        activity?.scope === undefined ? undefined : activity.scope === "PARTNER",
    isMeetupScope: (activity?: ActivityEntity): boolean | undefined =>
        activity?.scope === undefined ? undefined : activity.scope === "MEETUP",
    isRegularScope: (activity?: ActivityEntity): boolean | undefined =>
        activity?.scope === undefined ? undefined : activity.scope === "REGULAR",
    editAction: {
        saveStringAction: (activityId: number, data: string, fieldName: string, srmService: SrmService) =>
            srmService.saveActivity(activityId, { [fieldName]: data }),
        saveMultipleStringAction: (id: number, data: { data: string; fieldName: string }[], srmService: SrmService) => {
            const sendData = {};
            data.forEach((d) => {
                sendData[d.fieldName] = d.data;
            });
            return srmService.saveActivity(id, sendData);
        },
        saveLocalizeStringAction: (id: number, data: LocalizeString, fieldName: string, srmService: SrmService) =>
            srmService.saveActivity(id, { [fieldName]: data }),
        saveLocalizeStringContentDataAction: (
            activityId: number,
            data: LocalizeString,
            fieldName: string,
            srmService: SrmService,
        ) =>
            srmService.saveActivity(activityId, {
                content: {
                    data: {
                        [fieldName]: data,
                    },
                },
            }),
        saveStringContentDataAction: (id: number, data: string, fieldName: string, srmService: SrmService) =>
            srmService.saveActivity(id, {
                content: {
                    data: {
                        [fieldName]: data,
                    },
                },
            }),
        removeContentDataNotEditableFields: (data: ActivityContentData): object =>
            removeUndefined({
                ...data,
                id: undefined,
                OGImage: undefined,
                committeeComment: undefined,
                eventId: undefined,
                eventProject: undefined,
                eventVersion: undefined,
                fullDescription: undefined,
                links: undefined,
                marketingComment: undefined,
                options: undefined,
                presentation: undefined,
                shortDescription: undefined,
                title: undefined,
            }),
    },
};
