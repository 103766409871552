import React, { FC } from "react";

import cn from "classnames";

import styles from "./TopBar.module.scss";

import { observer } from "mobx-react-lite";
import { ProgressBar } from "primereact/progressbar";

import { useDataContext } from "../../../../data/DataContext";
import { useIsBrowser } from "../../../../hooks/useIsBrowser";
import DarkLightBar from "./DarkLightBar";
import HelpBar from "./HelpBar";
import LanguageBar from "./LanguageBar";
import MenuBar from "./MenuBar";
import Profile from "./Profile";

interface TopBarProps {
    lightMenu?: boolean;
}

const TopBar: FC<TopBarProps> = () => {
    const { isProgress, appState } = useDataContext().stores.appStateStore;
    const isBrowser = useIsBrowser();
    const { disableTips } = appState;

    const rightContents = () => (
        <ul className={styles.topBar__menu}>
            <HelpBar disableTips={disableTips} />
            <DarkLightBar />
            {isBrowser && <LanguageBar disableTips={disableTips} />}
            <Profile />
        </ul>
    );

    return (
        <div className={cn(styles.topBar)}>
            <div className={styles.topBar__progress}>
                {isProgress && <ProgressBar mode="indeterminate" style={{ height: "6px" }} />}
            </div>
            <MenuBar />
            {rightContents()}
        </div>
    );
};

export default observer(TopBar);
