import { LocaleMessagesType } from "../../../../hooks/useI18nLocaleFormat";
import { EntityTypeValue, PossibleValueForSelect } from "../../../common/common.models";
import { ActivityScope } from "../activity/activity.models";
import { MemberRole, Titulus } from "./member.models";

export const MemberEntityLocalizedIds = {
    format: "domain.proposal.member.participationFormat",
};

export const MemberEntityFormatValues = (scope: ActivityScope): EntityTypeValue<string>[] => {
    const isPartnerScope = scope === "PARTNER";
    const isMeetupScope = scope === "MEETUP";

    if (isMeetupScope) {
        return [
            {
                key: "ONLINE_REMOTE",
                displayId: "domain.proposal.member.participationFormat.ONLINE_REMOTE.meetup",
            },
            {
                key: "ONLINE_STUDIO",
                displayId: "domain.proposal.member.participationFormat.ONLINE_STUDIO.meetup",
            },
            {
                key: "UNKNOWN",
                displayId: "domain.proposal.member.participationFormat.UNKNOWN",
                disabled: true,
            },
        ];
    }

    if (isPartnerScope) {
        return [
            // {
            //     key: "OFFLINE",
            //     displayId: "domain.proposal.member.participationFormat.OFFLINE"
            // },
            {
                key: "ONLINE_REMOTE",
                displayId: "domain.proposal.member.participationFormat.ONLINE_REMOTE.partner",
            },
            {
                key: "ONLINE_STUDIO",
                displayId: "domain.proposal.member.participationFormat.ONLINE_STUDIO.partner",
            },
            {
                key: "UNKNOWN",
                displayId: "domain.proposal.member.participationFormat.UNKNOWN",
            },
        ];
    }

    return [
        {
            key: "ONLINE_REMOTE",
            displayId: "domain.proposal.member.participationFormat.ONLINE_REMOTE",
        },
        {
            key: "ONLINE_STUDIO",
            displayId: "domain.proposal.member.participationFormat.ONLINE_STUDIO",
        },
        {
            key: "OFFLINE",
            displayId: "domain.proposal.member.participationFormat.OFFLINE",
        },
        {
            key: "UNKNOWN",
            displayId: "domain.proposal.member.participationFormat.UNKNOWN",
        },
    ];
};

export const MemberEntityRoleValues = (declination: "1d" | "2d" = "1d"): EntityTypeValue<string>[] => [
    {
        key: "SPEAKER",
        displayId: `domain.proposal.member.role.${declination}.SPEAKER`,
    },
    {
        key: "EXPERT",
        displayId: `domain.proposal.member.role.${declination}.EXPERT`,
    },
    {
        key: "HOST",
        displayId: `domain.proposal.member.role.${declination}.HOST`,
    },
    {
        key: "PARTNER",
        displayId: `domain.proposal.member.role.${declination}.PARTNER`,
    },
    {
        key: "MEMBER",
        displayId: `domain.proposal.member.role.${declination}.MEMBER`,
    },
    {
        key: "EDITOR",
        displayId: `domain.proposal.member.role.${declination}.EDITOR`,
    },
    {
        key: "COORDINATOR",
        displayId: `domain.proposal.member.role.${declination}.COORDINATOR`,
    },
    {
        key: "MODERATOR",
        displayId: `domain.proposal.member.role.${declination}.MODERATOR`,
    },
];

export const TitulusPossibleValuesForSelect = (): PossibleValueForSelect[] =>
    TitulusPossibleValues.map((name) => ({
        name,
        code: name === "-" ? undefined : name,
    }));

export const TitulusPossibleValues: Titulus[] = [
    "Java Champion",
    "Microsoft Most Valuable Professional",
    "Google Developer Expert",
    "MVP Reconnect",
    "-",
];

export const RolesPossibleCodes: MemberRole[] = ["SPEAKER", "EXPERT", "HOST"];

export const RolesPossibleValuesForSelect = (localeAs: (id: LocaleMessagesType) => string): PossibleValueForSelect[] =>
    RolesPossibleCodes.map((code) => ({
        name: localeAs(MemberEntityRoleValues().find((v) => v.key === code).displayId as any),
        code,
    }));

export const BiographyLocalizedIds = {
    content: {
        data: {
            firstName: "page.proposal.content.tabs.speakers.infoBlock.firstName",
            lastName: "page.proposal.content.tabs.speakers.infoBlock.lastName",
            company: "page.proposal.content.tabs.speakers.infoBlock.company",
            description: "page.proposal.content.tabs.speakers.infoBlock.description",
            titulus: "page.proposal.content.tabs.speakers.infoBlock.titulus",
            twitter: "page.proposal.content.tabs.speakers.infoBlock.twitter",
            position: "page.proposal.content.tabs.speakers.infoBlock.position",
            email: "page.proposal.content.tabs.speakers.infoBlock.email",
            phone: "page.proposal.content.tabs.speakers.infoBlock.phone",
            role: "page.proposal.content.tabs.speakers.infoBlock.role",
            isPartner: "page.proposal.content.tabs.speakers.infoBlock.isPartner",
            isMeetup: "page.proposal.content.tabs.speakers.infoBlock.isMeetup",
        },
    },
};

export const MemberLocalizedIds = {
    biography: BiographyLocalizedIds,
    consent: {
        publicationConsent: "page.proposal.content.tabs.speakers.infoBlock.publicationConsent",
    },
};
