export const removeUndefined = (obj: object) => {
    if (Array.isArray(obj)) {
        return obj
            .filter((a) => a)
            .map((a) => {
                if (typeof a === "object") {
                    return removeUndefined(a);
                } else {
                    return a;
                }
            });
    }

    for (const k in obj) {
        if (obj[k] === undefined) {
            delete obj[k];
        } else if (typeof obj[k] === "object") {
            obj[k] = removeUndefined(obj[k]);
        }
    }
    return obj;
};

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

export function groupBy<K, V>(array?: V[], grouper?: (item: V) => K) {
    if (!array) {
        return undefined;
    }
    return array.reduce((store, item) => {
        const key = grouper(item);
        if (!store.has(key)) {
            store.set(key, [item]);
        } else {
            store.get(key).push(item);
        }
        return store;
    }, new Map<K, V[]>());
}

export function groupByWithExtractor<K, V, T>(array: V[], grouper: (item: V) => K, extractor: (item: V) => T) {
    if (!array) {
        return undefined;
    }
    return array.reduce((store, item) => {
        const key: K = grouper(item);
        if (!store.has(key)) {
            store.set(key, [extractor(item)]);
        } else {
            store.get(key).push(extractor(item));
        }
        return store;
    }, new Map<K, T[]>());
}

export function groupBySingle<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
        const key = grouper(item);
        if (!store.has(key)) {
            store.set(key, item);
        }
        return store;
    }, new Map<K, V>());
}

export function groupBySingleWithExtractor<K, V, T>(array: V[], grouper: (item: V) => K, extractor: (item: V) => T) {
    return array.reduce((store, item) => {
        const key = grouper(item);
        if (!store.has(key)) {
            store.set(key, extractor(item));
        }
        return store;
    }, new Map<K, T>());
}

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

export const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

export const numberWithSpaces = (nr) => nr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const arrayWeakEquals = (a, b) =>
    Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val) => b.some((v) => v === val));

export const deepEqual = function (x, y) {
    if (x === y) {
        return true;
    } else if (typeof x == "object" && x != null && typeof y == "object" && y != null) {
        if (Object.keys(x).length != Object.keys(y).length) {
            return false;
        }

        for (const prop in x) {
            if (y.hasOwnProperty(prop)) {
                if (!deepEqual(x[prop], y[prop])) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    } else {
        return false;
    }
};

export function flattenObject(obj: object, keysToRemove: string[]) {
    for (let key in obj) {
        // Check that we are running on the object key
        if (obj.hasOwnProperty(key)) {
            // Check to see if the current key is in the "black" list or not
            if (keysToRemove.indexOf(key) > -1) {
                // Process the inner object without this key
                delete obj[key];
            } else {
                if (typeof obj[key] === "object") {
                    flattenObject(obj[key], keysToRemove);
                }
            }
        }
    }

    return obj;
}
